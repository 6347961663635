
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../css/contact.css'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

export function Contact() {
    return (
        <div id='contactSection'>

                <div className={'contactLink'}>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <div className={'separator'}>{"|"}</div>
                    James.sc.ngo@gmail.com
                </div>
                <div className={'contactLink'}>
                    <FontAwesomeIcon icon={faLinkedin} />
                    <div className={'separator'}>{"|"}</div>
                    <a href="https://www.linkedin.com/in/jsc-ngo" target="_blank">Linkedin</a>
                    </div>
                <div className={'contactLink'}>
                    <FontAwesomeIcon icon={faGithub} />
                    <div className={'separator'}>{"|"}</div>
                    <a href="https://www.github.com/James-Ng0" target="_blank">Github</a> 
                    </div>
        </div>
    )
}