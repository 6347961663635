import '../css/skill.css';
import { useState, useEffect } from "react"
export function Skill( {name, description, icon} ) {
    const [expanded, setExpanded] = useState(false)
    const [hovered, setHovered] = useState(false)

    const toggleExpanded = () => {
        setExpanded(!expanded)
        closeAllSkills()
        openCurrentSkill()
    }

    const closeAllSkills = () => {
        Array.from(document.getElementsByClassName('skillDesc')).forEach((skill) => {
            if(skill.style.animationName == 'expandSkill' && skill.id != name){
                skill.style.animationName = 'closeSkill'
            }
        })
    }

    const openCurrentSkill = () => {
        setExpanded(true)
        let skill = document.getElementById(name)
        if(skill.style.animationName == 'expandSkill')
        {
            skill.style.animationName = 'closeSkill'
        }
        else if(skill.style.animationName == 'closeSkill')
        {
            skill.style.animationName = 'expandSkill'
        }
        else
        {
            skill.style.animationName = 'expandSkill'
        }   
    }

    const onAnimationEnd = () => {
        let skill = document.getElementById(name)
        if(skill.style.animationName == 'closeSkill')
        {
            setExpanded(false)
        }
    }

    return (
        <div className={'skill'} onClick={toggleExpanded}>
            <div className={"skillIcon"}>
                <img src={icon} className={'skillImage'} onMouseEnter={() => (setHovered(true))} onMouseLeave={() => (setHovered(false))} alt={name + ' logo'}/>
            </div>
            {(expanded || hovered) ? <div className={'skillName'}>{name}</div> :
            <div className={'skillnamePlaceHolder'}/>}
            <div className={'skillDesc'} id={name} onAnimationEnd={onAnimationEnd}>{description}</div>
        </div>
    )
}