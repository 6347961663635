import '../css/navbar.css'
import face from '../images/Myface.jpeg'
import CV from '../images/GameDevCV.pdf'
export function Navbar(){
    return(
        <nav id='bar'>
            <div id='logo'>
                <img id='logoImg' src={face}/>
            </div>
            <div id='links'>
                <div id='About' className='navBarItem'>
                    <a href={CV} target="_blank" className='navBarItem link'>Resume</a>
                </div>
                <div id='Skills' className='navBarItem'>
                    <a href='#skillsSection' className='navBarItem link'>Skills</a>
                </div>
                <div id='Projects' className='navBarItem'>
                <a href='#projectsTitle' className='navBarItem link'>Projects</a>
                </div>
                <div id='Contact' className='navBarItem'>
                <a href='#contactSection' className='navBarItem link'>Contact</a>
                </div>
            </div>
        </nav>
    );
}