import { HeadCard } from "../components/HeadCard";
import { ProjectsSection } from "../components/ProjectsSection";
import { SkillsSection } from "../components/SkillsSection";
import { Contact } from "../components/Contact";
import "../css/Portfolio.css";
import { Navbar } from "../components/navbar";
function Portfolio() {
  return (
    <div id="page">
      <div id="nav">
        <Navbar />
      </div>
      <div id="air">
        <HeadCard />
      </div>
      <div id="sea">
        <SkillsSection />
        <ProjectsSection />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </div>
  );
}

export default Portfolio;
