import { useState, useEffect } from "react";
import "../css/project.css";
import defaultImg from "../images/sass.png";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

export function Project({
  title,
  description,
  extendedDescription = "",
  projectImage,
  Left,
  github = "",
  demo = "",
  closeAllProjects,
}) {
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => {
    closeAllProjects(title);
    toggleCurrentProject();
  };

  const toggleCurrentProject = () => {
    let project = document.getElementById(title);
    let image = document.getElementById(title + "Image")
    let text = document.getElementById(title + "Text")

    if (project.style.animationName == "expandProject") {
      project.style.animationName = "closeProject";
      image.style.animationName = "closeProjectImage"
      text.style.animationName = "closeProjectText"
      text.scrollTo(0,0)
    
    } else {
      project.style.animationName = "expandProject";
      image.style.animationName = "expandProjectImage"
      text.style.animationName = "expandProjectText"
      setExpanded(true)
    }
  };

  const endAnimation = () => {
    let element = document.getElementById(title);
    console.log(element.style.animationName)
    if(element.style.animationName == 'closeProject'){
      setExpanded(false);
    }
  }

  const formatIntoList = () => {
    return(
      <ul>
        {extendedDescription.map(x => 
          <li>{x}</li>
          )
        }
      </ul>
    )
  }

  return (
    <div
      className={"project " + (Left ? "reverse " : "")}
      id={title}
      onClick={toggleExpanded}
      onAnimationEnd={ endAnimation }
    >
      <div className={"projectImageBox "} id={title + "Image"}>
        <img src={projectImage} className={"projectImage"}/>
      </div>

      <div className={"projectText"} id={title+"Text"}>
        <div>
          <div className={"projectTitle"}>{title}</div>
          <div className={"projectDescription"}>{description}
          <br/><br/>
          {expanded && formatIntoList()}
          </div>
        </div>
        {expanded && <div className={"linksSection"}>
          {github != "" && <div className={"projectLink"}>
            <FontAwesomeIcon icon={faGithub} />
            <div className={"separator"}>{"|"}</div>
            <a href={github} target="_blank">
              Github
            </a>
          </div>}
            {demo != "" && <div className={"projectLink"}>
            <FontAwesomeIcon icon={faPlay} />
            <div className={"separator"}>{"|"}</div>
            <a href={demo} target="_blank">
              Demo
            </a>
          </div>}
        </div>}
      </div>
    </div>
  );
}
