import "../css/skillsSection.css";
import { Skill } from "./Skill";
import reactLogo from "../images/logo512.png";
import csLogo from "../images/c#.png";
import pythonLogo from "../images/pythonlogo.png";
import nodejsLogo from "../images/nodejslogo.png";
import unity from "../images/unity.png";
import cpp from "../images/ISO_C++_Logo.svg.png";
import js from "../images/js.png";
import sass from "../images/sass.png";

export function SkillsSection() {
  return (
    <div id="skillsSection">
      <div id="skillsSectionTitle">Skills</div>
      <div id="skills">
        <div className="flexRow">
          <Skill
            name={"Unity"}
            description={
              "Used Unity as a hobbyist to collaborate during game jams as well as for personal game development."
            }
            icon={unity}
          />
          <Skill
            name={"C#"}
            description={
              "Used C# professionally through .NET as well as to create games in Unity"
            }
            icon={csLogo}
          />
          <Skill
            name={"C++"}
            description={
              "Used C++ throughout my university Computer Graphics courses to create and alter 3D rendering and image manipulation pipelines."
            }
            icon={cpp}
          />
          <Skill
            name={"React"}
            description={
              "Used React to build client facing websites and in my own time to create websites like this."
            }
            icon={reactLogo}
          />
        </div>

        <div className="flexRow">
          <Skill
            name={"JS"}
            description={
              "Worked with JavaScript both professionally to add page functionality as well as for hobbyist projects such as Disco Jungle."
            }
            icon={js}
          />
          <Skill
            name={"NodeJS"}
            description={
              "Currently teaching myself NodeJS to work as a backend for React."
            }
            icon={nodejsLogo}
          />
          <Skill
            name={"Sass"}
            description={
              "Used CSS/Sass to style pages professionally and personally."
            }
            icon={sass}
          />
          <Skill
            name={"Python"}
            description={
              "Used Python throughout university for AI and Machine Learning Papers as well as Image processing in OpenCV."
            }
            icon={pythonLogo}
          />
        </div>
      </div>
    </div>
  );
}
