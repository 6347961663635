import '../css/HeadCard.css'
import face from '../images/Myface.jpeg'
export function HeadCard(){
    return(
        <div id='headCard'>
            <div id='text'>
                <div id='name'>
                    James Ngo
                </div>
                <div id='title'>
                    Game Developer
                </div>
                <div id='description'>
                    Hi, I'm James, I'm an indie game developer working in Unity.
                    
                    <br/><br/>
                    I'm passionate about building fun and novel player experiences, and am always keen to learn more about game development. feel free to check out my projects down below.
                    <br/>
                    
                </div>
            </div>
            <div id='image'>
                <img src={face} alt="That's me in the corner" id='face'/>
            </div>
            
        </div>
    )
}