import { Project } from "./Project";
import "../css/projectsSection.css";
import { useState, useEffect } from "react";
import theFacility from "../images/theFacility.png";
import ROOTS from "../images/ROOTS.png";
import mothSurvival from "../images/mothSurvival.png";
import robotReplacer from "../images/robotReplacer.png";
import pinballPlanner from "../images/pinballPlanner.png";
import demonBow from "../images/demonBow.png";
import questLog from "../images/questLog.png";
import discoJungle from "../images/discoJungle.png";
import timeline from "../images/timeline.png";
import waterWaves from "../images/WaterWaves.png";
import pathfinder from "../images/pathfinder.png";
import defaultImg from "../images/sass.png";
import orcaWhaleVolleyball from "../images/orcaWhaleVolleyball.png";
import codingGuide from "../images/codingGuide.png";
import lighthouse from "../images/lighthouse.png";
export function ProjectsSection() {
  const [projectType, setProjectType] = useState("Projects");
  const [projectTypeToggled, setProjectTypeToggled] = useState(projectType);

  const gameDevProjects = [
    {
      title: "Lighthouse Keeper",
      description:
        "Play as a lighthouse keeper on a lonely lighthouse. Correspond with unsettling townsfolk to unravel the mystery of the island.",
      extendedDescription:
        ["Implemented a Gerstner wave shader using Unity Shader Graph.", "Built enemy AI using Unity Navmesh.", "Created interactive UIs for crafting and mail."],
      projectImage: lighthouse,
      github: "https://github.com/James-Ng0/The-Lighthouse",
    },
    {
      title: "The facility",
      description:
        "A 2D puzzle platformer in which you grapple your way through a mysterious facility.",
      extendedDescription:
      ["Gained experience setting realistic scope and building a product start to finish.", "Built a robust player state machine in C#", "Designed challenging logic puzzles."],
      projectImage: theFacility,
      github: "https://github.com/James-Ng0/Platformer",
      demo: "https://james-ng0.itch.io/the-facility",
    },
    {
      title: "Relocation of Outer Terrestrial Species (ROOTS)",
      description:
        "Made for 2023 Global Game Jam, listen to the preferences of a variety of aliens and help them find their ideal home.",
      projectImage: ROOTS,
      extendedDescription:
        ["Collaborated with an artist, product manager and second coder.", "Increased proficiency with Unity's UI system.", "Gained experience collaborating with other programmers in Unity via Github."],
      github: "https://github.com/Benjamin-Fever/Global-Game-Jam-2023",
      demo: "https://olivieryc.itch.io/relocation-of-outer-terrestrial-species",
    },
    {
      title: "Orca Whale Volleyball",
      description:
        "A multipayer 3D game in which you play volleyball as an orca whale.",
      extendedDescription:
        ["Replicated orca whale movement using Unity's inverse kinematics system.", "Gained experience using Unity shader graph."],
      projectImage: orcaWhaleVolleyball,
      github: "https://github.com/James-Ng0/OrcaWhaleVolleyball",
    },
    {
      title: "Moth Survival",
      description:
        "Craft, build and survive on a planet invaded by deadly giant moths. This game was dropped due to scope, but many core features are present.",
      projectImage: mothSurvival,
      extendedDescription:
        ["Implemented a chunking system to enhance performance on an infinite procedural world.", "Wrote inventory, crafting and building mechanics.", "Built a chest storage system.", "Implemented saving/loading worlds."],
      github: "https://github.com/James-Ng0/SurvivalGame",
      demo: "https://james-ng0.itch.io/survival-game",
    },
    {
      title: "Demon Bow",
      description: "A 2D roguelike in the style of Dead Cells.",
      extendedDescription:
        ["Implemented roguelike procedural level generation using a Binary Split Partition room algorithm."],
      projectImage: demonBow,
      github: "https://github.com/James-Ng0/Demon-Bow",
    },
    {
      title: "Pinball Planner",
      description:
        "Built for Seepia Tiny Game Jam 2023. Build a course to navigate a pinball through a minefield.",
      extendedDescription:
        ["Gained experience deploying a build for my first game jam.", "Learned basic 2D lighting using Unity URP."],
      projectImage: pinballPlanner,
      demo: "https://james-ng0.itch.io/pinball-planner",
    },
  ];

  const webDevProjects = [
    {
      title: "Disco Jungle",
      description:
        "A cute endless runner game in which you run, jump and swing from platforms. Built in HTML canvas as a replacement for the dinosaur game, distance earns you coins which can be used to purchase cosmetic upgrades from a store.",
      projectImage: discoJungle,
      extendedDescription:
        ["Implemented basic game physics in Javascript.", "Learned about storing player data via cookies.", "Created a shop allowing players to equip cosmetic items."],
      github: "https://github.com/James-Ng0/disco_jungle",
      demo: "../webProjects/discoJungle/index.html",
    },
    {
      title: "QuestLog",
      description:
        "A react native mobile application built to track completion of larger life goals. Supports adding quests and subquests allowing large tasks to be tracked.",
      projectImage: questLog,
      extendedDescription:
        ["Wrote code to asynchronously handle recursion when updating quests.", "Implemented CRUD operations for quests in React Native."],
      github: "https://github.com/James-Ng0/QuestLog",
    },
  ];

  const otherProjects = [
    {
      title: "OpenGL wave simulation",
      description:
        "Based on the paper http://www.cemyuksel.com/research/waveparticles/ I implemented this algorithm in C++ OpenGL for my 3rd year Computer Graphics capstone project",
      extendedDescription:
      [],
      projectImage: waterWaves,
    },
    {
      title: "Programming guide",
      description:
        "This guide was written as a passion project to help my friends learn how to code. I love teaching programming and used python notebooks to build an interactive guide.",
      extendedDescription:
        ["Covered the content of a first year university programming paper", "Wrote challenges allowing users to test out what they have learnt."],
      demo: "https://drive.google.com/drive/folders/1RpSLQRyK_nazSPRix2VPhzcStKXPaVET?usp=drive_link",
      projectImage: codingGuide,
    },
  ];

  const getProjects = () => {
    switch (projectType) {
      case "Game Development":
        return gameDevProjects;
      case "Web Development":
        return webDevProjects;
      case "Other Projects":
        return otherProjects;
      case "Projects":
        return gameDevProjects.concat(webDevProjects, otherProjects);
    }
  };
  let currentProjects = getProjects();

  const swipeIn = () => {
    let projects = document.getElementById("projects");
    if (projects.style.animationName == "projectSwipeOut") {
      projects.style.animationName = "projectSwipeIn";
      setProjectType(projectTypeToggled);
    }
  };

  useEffect(() => {
    closeAllProjects();
    let projects = document.getElementById("projects");
    projects.style.animationName = "projectSwipeOut";
    let projectToggleButtons = document.getElementsByClassName("toggleOption");
    Array.from(projectToggleButtons).map((button) => {
      let text = button.textContent;
      button.setAttribute("class", "toggleOption");
      if (text == projectTypeToggled) {
        button.setAttribute("class", "toggleOption selectedToggleOption");
      }
    });
  }, [projectTypeToggled]);

  const closeAllProjects = (title = "") => {
    //Close project boxes
    Array.from(document.getElementsByClassName("project")).forEach(
      (project) => {
        if (project.style.height != "200px" && project.id != title) {
          project.style.animationName = "closeProject";
        }
      }
    );

    //Close project images
    Array.from(document.getElementsByClassName("projectImageBox")).forEach(
      (Image) => {
        if (Image.style.height != "200px" && Image.id != title + "Image") {
          Image.style.animationName = "closeProjectImage";
        }
      }
    );

    Array.from(document.getElementsByClassName("projectText")).forEach(
      (text) => {
        if (text.style.height != "200px" && Image.id != title + "Text") {
          text.style.animationName = "closeProjectText";
          text.scrollTo(0, 0);
        }
      }
    );
  };

  const toggleProjectType = (type) => {
    if (projectType == type) {
      setProjectTypeToggled("Projects");
    } else {
      setProjectTypeToggled(type);
    }
  };
  return (
    <div id="projectsSection" onAnimationEnd={swipeIn}>
      <div id={"projectsTitle"}>{projectTypeToggled}</div>
      <div id={"sectionToggle"}>
        <button
          className={"toggleOption"}
          onClick={() => toggleProjectType("Game Development")}
        >
          Game Development
        </button>
        <button
          className={"toggleOption"}
          onClick={() => toggleProjectType("Web Development")}
        >
          Web Development
        </button>
        <button
          className={"toggleOption"}
          onClick={() => toggleProjectType("Other Projects")}
        >
          Other
        </button>
      </div>
      <div id={"projects"}>
        {currentProjects.map((p, ix) => {
          return (
            <Project
              title={p.title}
              description={p.description}
              extendedDescription={p.extendedDescription}
              projectImage={p.projectImage}
              closeAllProjects={closeAllProjects}
              Left={ix % 2 == 0}
              github={p.github}
              demo={p.demo}
              key={ix}
            />
          );
        })}
      </div>
    </div>
  );
}
